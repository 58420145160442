.App {
  background-color: #f4f4f4;
  min-height: 100vh;
  color: #398378;
}
.app-header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
h1,
.h5 {
  color: #398378 !important;
}

.container {
  min-height: 76vh;
}
.cta {
  margin-top: 6px;
  background-color: #398378;
  border: none;
  color: white;
  text-transform: uppercase;
  transition: background-color 0.3s;
}
.cta:hover {
  background-color: #00332e;
}

.footer {
  background-color: #398378;
  color: white;
  padding: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.list-group {
  max-height: 50vh;
  overflow: auto;
}

ol {
  counter-reset: item;
}
ol li {
  display: flex;
  justify-content: space-between;
}
ol li::before {
  content: counter(item) ". ";
  counter-increment: item;
}
ol.reverse {
  display: flex;
  flex-direction: column-reverse;
}
